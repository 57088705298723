<template>
  <div>
    <v-card>
      <v-tabs background-color="rgba(0,0,0,0.05)">
        <v-tab
          v-if="false"
          @change="
            () => {
              cityDistrictTabActive = true;
              projectTabActive = false;
            }
          "
        >
          <v-icon left>
            mdi-home-city-outline
          </v-icon>
          Ort / Ortsteil
        </v-tab>
        <v-tab
          @change="
            () => {
              cityDistrictTabActive = false;
              projectTabActive = true;
            }
          "
        >
          <v-icon left>
            mdi-vector-polygon
          </v-icon>
          Projekt
        </v-tab>
        <v-tab-item v-if="cityDistrictTabActive">
          <v-card-text>
            <AddressDistrictSelection
              :city="city"
              :district="district"
              @updateCity="updateCity"
              @updateDistrict="updateDistrict"
              @updateDistrictObject="updateDistrictObject"
            />
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-row>
              <v-col>
                <!-- <ProjectNameSelection
                  v-if="!userGroups.includes('LgSpotNU')"
                  :projectItem="projectItem"
                  :fullWidth="true"
                  @updateProjektItem="updateProjektItem"
                />
                <v-select
                  v-else-if="userGroups.includes('LgSpotNU')"
                  v-model="projectItem"
                  :items="availableProjectsNu"
                  :item-text="getProjectItemText"
                  label="Projekt"
                  return-object
                /> -->
                <ProjectNameSelection
                  :projectItem="projectItem"
                  :fullWidth="true"
                  @updateProjektItem="updateProjektItem"
                />
              </v-col>
              <v-col>
                <div v-if="projectItem && !userGroups.includes('LgSpotNU')">
                  <v-row>
                    <v-col
                      cols="1"
                      align-self="center"
                      style="max-width: 4.333%"
                    >
                      <v-icon>mdi-update</v-icon>
                    </v-col>
                    <v-col>
                      <v-combobox
                        v-model="newProjectState"
                        :items="projectStates"
                        item-disabled="disable"
                        item-text="name"
                        color="primary"
                        label="Projektstatus wählen..."
                      />
                    </v-col>
                    <v-col>
                      <DatePicker
                        label="Datum für den Baustart"
                        :value="showConstructionStartDate"
                        disableWeekend
                        disableHolidays
                        :disabled="
                          newProjectState.name !=
                            projectStateConstants.PROJECT_CONSTRUCTION_STARTED
                              .value
                        "
                        @input="updateConstructionStartDate($event)"
                      />
                    </v-col>
                    <v-col>
                      <v-select
                        v-model="constructionManagementTool"
                        :items="constructionManagementToolOptions"
                        item-disabled="disable"
                        color="primary"
                        label="Bauplannungstool wählen..."
                        disabled
                      />
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-btn
          v-if="cityDistrictTabActive"
          class="margin-right-1"
          color="primary"
          :disabled="!district"
          @click="getHouseconnectionContractsByCityCodeAndDistrict"
        >
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          Adressen laden
        </v-btn>
        <v-btn
          v-if="projectTabActive"
          class="margin-right-1"
          color="primary"
          :disabled="!projectItem"
          @click="getProjectData"
        >
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          Adressen laden
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-4">
      <v-data-table
        v-model="selected"
        :headers="onlineKblHeaders"
        :items="filteredAddresses"
        item-key="ags27"
        class="elevation-1"
        :item-class="rowClass"
        :single-expand="singleExpand"
        :loading="dataLoading"
        :expanded.sync="expanded"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        loading-text="Daten werden geladen..."
        no-data-text="Es sind keine Einträge vorhanden."
        no-results-text="Für den angegebenen Ort konnten keine Einträge gefunden werden."
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        }"
        :items-per-page.sync="rowsPerPageDefault"
        @item-expanded="
          if ($event.value === true) {
            loadHouseConnectionOrdersAtInstallationAddress($event.item);
            loadServiceOrdersAtInstallationAddress($event.item);
          }
        "
        @click:row="expandRow"
      >
        <!-- <tr v-bind:class="{'active-class-name': isActive(item)}"> -->
        <!-- <template v-slot:[`item.constructionStatus`]="{ item }">
            <v-chip
              v-if="item.constructionStatus && item.constructionStatus.description"
              :color="item.constructionStatus.color"
              dark
              label
              outlined
            >
              <v-icon>mdi-bulldozer</v-icon>
              {{ item.constructionStatus.description }}
            </v-chip>
            <span v-else> - </span>
          </template> -->
        <template v-slot:[`item.ags27`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <div
                  v-if="
                    item.houseConnectionBuilt &&
                      item.houseConnectionPatched &&
                      item.popOnline
                  "
                  class="status-green"
                ></div>
                <div
                  v-else-if="item.houseConnectionBuilt"
                  class="status-yellow"
                ></div>
                <div v-else class="status-white"></div>
              </div>
            </template>
            <!-- <span
              v-if="
                item.houseConnectionBuilt &&
                  item.houseConnectionPatched &&
                  item.popOnline
              "
            >Ready for Service</span>
            <span v-else>Not Ready for Service</span> -->
          </v-tooltip>
        </template>
        <template v-slot:[`item.markedForSecondConstruction`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="float-left"
                v-on="on"
              >
                <v-icon
                  v-if="item.houseConnectionBuilt"
                  color="rgb(106, 168, 79)"
                >
                  mdi-home-alert
                </v-icon>
                <v-icon v-else color="#aa0000">
                  mdi-home-alert
                </v-icon>
              </div>
            </template>
            <span>Hausanschluss gebaut</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="float-left"
                v-on="on"
              >
                <v-icon
                  v-if="item.houseConnectionPatched"
                  color="rgb(106, 168, 79)"
                >
                  mdi-home-assistant
                </v-icon>
                <v-icon v-else color="#aa0000">
                  mdi-home-assistant
                </v-icon>
              </div>
            </template>
            <span>Hausanschluss gepatcht</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="float-left"
                v-on="on"
              >
                <v-icon v-if="item.popOnline" color="rgb(106, 168, 79)">
                  mdi-transit-connection-variant
                </v-icon>
                <v-icon v-else color="#aa0000">
                  mdi-transit-connection-variant
                </v-icon>
              </div>
            </template>
            <span>POP Online</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="float-left"
                v-on="on"
              >
                <v-icon v-if="item.markedForSecondConstruction" color="#00bbff">
                  mdi-account-hard-hat
                </v-icon>
              </div>
            </template>
            <span>vorgemerkt 2. Bauabschnitt</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.address.houseNumber`]="{ item }">
          {{ item.address.houseNumber }}
          {{ item.address.houseNumberSupplement }}
        </template>
        <template v-slot:[`item.houseConnectionsNo`]="{ item }">
          <template v-if="item.houseConnectionsNo">
            <template v-for="(element, index) in item.houseConnectionsNo">
              <v-icon :key="index" color="success">
                mdi-home
              </v-icon>
            </template>
          </template>
          <template
            v-else-if="item.serviceConnectionsNo && !item.houseConnectionsNo"
          >
            <v-icon :key="index" color="error">
              mdi-home-floor-0
            </v-icon>
          </template>
        </template>
        <template v-slot:[`item.serviceConnectionsNo`]="{ item }">
          <div v-if="item.serviceConnectionsNo">
            <template v-for="(element, index) in item.serviceConnectionsNo">
              <v-icon :key="index" color="success">
                mdi-file-document-outline
              </v-icon>
            </template>
          </div>
        </template>
        <template #top>
          <v-toolbar flat color="rgba(0, 0, 0, 0.05)">
            <v-toolbar-title>
              Online Kundenbauliste für Projekt
              <b>{{ projectItem.projektId }}, {{ projectItem.name }}</b>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="filter-box">
              <v-icon class="mr-1">
                mdi-home-search
              </v-icon>
              <v-select
                v-model="filterCategory"
                :items="filterCategories"
                item-text="label"
                item-value="value"
                style="max-width: 140px"
                class="mt-2 mr-1"
              ></v-select>
              <v-text-field
                v-model="filterInput"
                label="Suche"
                :disabled="filterCategory ? false : true"
                style="max-width: 260px"
                class="mt-2"
              ></v-text-field>
            </div>
            {{ filteredAgsKeys }}
          </v-toolbar>
          <div class="filter-bar px-2">
            <v-switch
              v-model="onlyMarkedForSecondConstruction"
              label="nur für 2. Baubschnitt vorgemerkte Adressen"
            />
          </div>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="onlineKblHeaders.length">
            <div class="ma-4 pa-2" style="display: contents">
              <div class="my-4">
                <h3>Adressdetails</h3>
                <v-row>
                  <v-col md="3">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon class="pr-4">
                            mdi-map-check
                          </v-icon>
                          {{ item.ags27 }}
                        </div>
                      </template>
                      <span>Adress-ID</span>
                    </v-tooltip>
                  </v-col>
                  <v-col md="1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon class="pr-4">
                            mdi-home-account
                          </v-icon>
                          <b>{{ item.numberOfHouses }}</b>
                        </div>
                      </template>
                      <span>Wohneinheiten</span>
                    </v-tooltip>
                  </v-col>
                  <v-col md="1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-icon class="pr-4">
                            mdi-factory
                          </v-icon>
                          <b>{{ item.numberOfOffices }}</b>
                        </div>
                      </template>
                      <span>Gewerbeeinheiten</span>
                    </v-tooltip>
                  </v-col>
                  <v-col md="2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-btn
                            small
                            @click="
                              getPropertyForMap(item), (selectedItem = item)
                            "
                          >
                            <v-icon>mdi-map-search</v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>Adresse auf Karte anzeigen</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
              <h4
                v-if="currentHouseConnections && currentHouseConnections.length"
              >
                Hausanschlussauftrag
              </h4>
              <h3
                v-else-if="
                  !currentHouseConnections || currentHouseConnections.length < 1
                "
                class="mb-2"
              >
                Hausanschluss Verbindungsdaten
                <small><i>(Hausanschlussauftrag liegt nicht vor!)</i></small>
              </h3>
              <v-card>
                <v-simple-table
                  v-if="
                    currentHouseConnections && currentHouseConnections.length
                  "
                  dense
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="200px">
                          Typ
                        </th>
                        <th class="text-left" width="120px">
                          Vertrag
                        </th>
                        <th class="text-left" width="120px">
                          Kunden-Nr.
                        </th>
                        <th class="text-left">
                          Unterschriftsdatum
                        </th>
                        <th class="text-left">
                          Vorname
                        </th>
                        <th class="text-left">
                          Nachname
                        </th>
                        <th class="text-left">
                          Firma
                        </th>
                        <th class="text-left">
                          E-Mail
                        </th>
                        <th class="text-left">
                          Telefon
                        </th>
                        <th class="text-left">
                          Mobil
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="contract in currentHouseConnections">
                        <tr
                          :key="contract.contractId"
                          @click="
                            (openHouseConnectionDialog = true),
                            (selectedProperty = item.ags27),
                            (selectedContract = contract.contractId
                              ? contract.contractId
                              : null),
                            (selectedStreetNr =
                              item.address.street +
                              ' ' +
                              item.address.houseNumber +
                              (item.address.houseNumberSupplement
                                ? ' ' + item.address.houseNumberSupplement
                                : ''))
                          "
                        >
                          <td><v-icon>mdi-home</v-icon> Hausanschluss</td>
                          <td>{{ contract.contractId }}</td>
                          <td>{{ contract.customerId }}</td>
                          <td>{{ formatDate(contract.dateSigned) }}</td>
                          <td>{{ contract.firstName }}</td>
                          <td>{{ contract.lastName }}</td>
                          <td>{{ contract.company }}</td>
                          <td>{{ contract.email }}</td>
                          <td>{{ contract.phone }}</td>
                          <td>{{ contract.cellPhoneNumber }}</td>
                        </tr>
                        <tr
                          :key="contract.contractId"
                          @click="
                            (openHouseConnectionDialog = true),
                            (selectedProperty = item.ags27),
                            (selectedContract = contract.contractId),
                            (selectedStreetNr =
                              item.address.street +
                              ' ' +
                              item.address.houseNumber +
                              (item.address.houseNumberSupplement
                                ? ' ' + item.address.houseNumberSupplement
                                : ''))
                          "
                        >
                          <td colspan="10">
                            <v-simple-table
                              class="mb-4"
                              dense
                              style="background-color: rgba(255, 185, 0, 0.1)"
                            >
                              <thead>
                                <tr>
                                  <th class="text-left" width="10">
&nbsp;
                                  </th>
                                  <th class="text-left">
                                    MFG
                                  </th>
                                  <th class="text-left">
                                    NVT
                                  </th>
                                  <th class="text-left">
                                    NVT-Spleißkassete
                                  </th>
                                  <th class="text-left">
                                    Rohrverband
                                  </th>
                                  <th class="text-left">
                                    Einzelrohr
                                  </th>
                                  <th class="text-left">
                                    Faseranzahl
                                  </th>
                                  <th class="text-left">
                                    OTDR-Protokoll
                                  </th>
                                  <th class="text-left">
                                    Begehungsprotokoll
                                  </th>
                                  <th class="text-left">
                                    Montagenachweis
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td width="10">
                                    <v-btn
                                      text
                                      fab
                                      x-small
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </td>
                                  <template
                                    v-if="currentHouseConnectionListData"
                                  >
                                    <td>
                                      {{ currentHouseConnectionListData.mfg }}
                                    </td>
                                    <td>
                                      {{ currentHouseConnectionListData.nvt }}
                                    </td>
                                    <td>
                                      {{
                                        currentHouseConnectionListData.nvtSpliceCassette
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        currentHouseConnectionListData.pipeUnion
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        currentHouseConnectionListData.singlePipe
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        currentHouseConnectionListData.numberOfFibers
                                      }}
                                    </td>
                                    <td>
                                      <v-icon
                                        v-if="
                                          currentHouseConnectionListData.otdrProtocol
                                        "
                                        color="success"
                                      >
                                        mdi-check-circle
                                      </v-icon>
                                      <v-icon v-else color="error">
                                        mdi-close-circle-outline
                                      </v-icon>
                                    </td>
                                    <td>
                                      {{
                                        currentHouseConnectionListData.inspectionReport
                                      }}
                                    </td>
                                    <td>
                                      <v-icon
                                        v-if="
                                          currentHouseConnectionListData.proofOfInstallation
                                        "
                                        color="success"
                                      >
                                        mdi-check-circle
                                      </v-icon>
                                      <v-icon v-else color="error">
                                        mdi-close-circle-outline
                                      </v-icon>
                                    </td>
                                  </template>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
                <!-- Ohne Hausanschlussauftag  -->
                <template
                  v-if="
                    !currentHouseConnections ||
                      currentHouseConnections.length < 1
                  "
                >
                  <v-simple-table
                    class="mb-4"
                    dense
                    style="background-color: rgba(255, 100, 0, 0.1)"
                  >
                    <thead>
                      <tr>
                        <th class="text-left" width="10">
&nbsp;
                        </th>
                        <th class="text-left">
                          MFG
                        </th>
                        <th class="text-left">
                          NVT
                        </th>
                        <th class="text-left">
                          NVT-Spleißkassete
                        </th>
                        <th class="text-left">
                          Rohrverband
                        </th>
                        <th class="text-left">
                          Einzelrohr
                        </th>
                        <th class="text-left">
                          Faseranzahl
                        </th>
                        <th class="text-left">
                          OTDR-Protokoll
                        </th>
                        <th class="text-left">
                          Begehungsprotokoll
                        </th>
                        <th class="text-left">
                          Montagenachweis
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        @click="
                          (openHouseConnectionDialog = true),
                          (selectedProperty = item.ags27),
                          (selectedContract =
                            contract && contract.contractId
                              ? contract.contractId
                              : null),
                          (selectedStreetNr =
                            item.address.street +
                            ' ' +
                            item.address.houseNumber +
                            (item.address.houseNumberSupplement
                              ? ' ' + item.address.houseNumberSupplement
                              : ''))
                        "
                      >
                        <td width="10">
                          <v-btn
                            text
                            fab
                            x-small
                          >
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </td>
                        <template v-if="currentHouseConnectionListData">
                          <td>
                            {{ currentHouseConnectionListData.mfg }}
                          </td>
                          <td>
                            {{ currentHouseConnectionListData.nvt }}
                          </td>
                          <td>
                            {{
                              currentHouseConnectionListData.nvtSpliceCassette
                            }}
                          </td>
                          <td>
                            {{ currentHouseConnectionListData.pipeUnion }}
                          </td>
                          <td>
                            {{ currentHouseConnectionListData.singlePipe }}
                          </td>
                          <td>
                            {{ currentHouseConnectionListData.numberOfFibers }}
                          </td>
                          <td>
                            <v-icon
                              v-if="currentHouseConnectionListData.otdrProtocol"
                              color="success"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon v-else color="error">
                              mdi-close-circle-outline
                            </v-icon>
                          </td>
                          <td>
                            {{
                              currentHouseConnectionListData.inspectionReport
                            }}
                          </td>
                          <td>
                            <v-icon
                              v-if="
                                currentHouseConnectionListData.proofOfInstallation
                              "
                              color="success"
                            >
                              mdi-check-circle
                            </v-icon>
                            <v-icon v-else color="error">
                              mdi-close-circle-outline
                            </v-icon>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </template>
              </v-card>
              <br />
              <h4>Serviceaufträge</h4>
              <v-card>
                <v-simple-table class="mb-4" dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" width="200px">
                          Produkt
                        </th>
                        <th class="text-left" width="120px">
                          Home-ID
                        </th>
                        <th class="text-left" width="120px">
                          Vertrag
                        </th>
                        <th class="text-left" width="120px">
                          Kunden-Nr.
                        </th>
                        <th class="text-left">
                          Unterschriftsdatum
                        </th>
                        <th class="text-left">
                          Vorname
                        </th>
                        <th class="text-left">
                          Nachname
                        </th>
                        <th class="text-left">
                          Firma
                        </th>
                        <th class="text-left">
                          E-Mail
                        </th>
                        <th class="text-left">
                          Telefon
                        </th>
                        <th class="text-left">
                          Mobil
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(contract, index) in currentServiceConnections"
                      >
                        <tr
                          :key="index"
                          @click="
                            (openServiceConnectionDialog = true),
                            (selectedProperty = item.ags27),
                            (selectedContract = contract.contractId),
                            (selectedHomeId = contract.homeId),
                            (selectedStreetNr =
                              item.address.street +
                              ' ' +
                              item.address.houseNumber +
                              (item.address.houseNumberSupplement
                                ? ' ' + item.address.houseNumberSupplement
                                : ''))
                          "
                        >
                          <td>
                            <v-icon>mdi-web</v-icon>
                            <template
                              v-if="contract.products && contract.products[0]"
                            >
                              {{ contract.products[0] }}
                            </template>
                          </td>
                          <td>{{ contract.homeId }}</td>
                          <td>{{ contract.contractId }}</td>
                          <td>{{ contract.customerId }}</td>
                          <td>{{ formatDate(contract.dateSigned) }}</td>
                          <td>{{ contract.firstName }}</td>
                          <td>{{ contract.lastName }}</td>
                          <td>{{ contract.company }}</td>
                          <td>{{ contract.email }}</td>
                          <td>{{ contract.phone }}</td>
                          <td>{{ contract.cellPhoneNumber }}</td>
                        </tr>
                        <tr
                          :key="index"
                          @click="
                            (openServiceConnectionDialog = true),
                            (selectedProperty = item.ags27),
                            (selectedContract = contract.contractId),
                            (selectedHomeId = contract.homeId),
                            (selectedStreetNr =
                              item.address.street +
                              ' ' +
                              item.address.houseNumber +
                              (item.address.houseNumberSupplement
                                ? ' ' + item.address.houseNumberSupplement
                                : ''))
                          "
                        >
                          <td colspan="11">
                            <v-simple-table
                              class="mb-4"
                              dense
                              style="background-color: rgba(255, 255, 0, 0.1)"
                            >
                              <thead>
                                <tr>
                                  <th class="text-left" width="10">
&nbsp;
                                  </th>
                                  <th class="text-left">
                                    GF_NR
                                  </th>
                                  <th class="text-left">
                                    TA_Lage
                                  </th>
                                  <th class="text-left">
                                    MFG_Spleißpanel
                                  </th>
                                  <th class="text-left">
                                    MFG_Panelport
                                  </th>
                                  <th class="text-left">
                                    MFG_Dämpfung
                                  </th>
                                  <th class="text-left">
                                    ONT_SN
                                  </th>
                                  <th class="text-left">
                                    OTDR-Protokoll
                                  </th>
                                  <th class="text-left">
                                    Montagenachweis
                                  </th>
                                  <th class="text-left">
                                    Device_Hostname
                                  </th>
                                  <th class="text-left">
                                    Device_Slot
                                  </th>
                                  <th class="text-left">
                                    Device_Port
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td width="10">
                                    <v-btn
                                      text
                                      fab
                                      x-small
                                    >
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </td>
                                  <template
                                    v-if="
                                      serviceOrderConnectionListArray &&
                                        serviceOrderConnectionListArray[index]
                                    "
                                  >
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .gfNumber
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .taLocation
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .mfgSplicePanel
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .mfgPanelPort
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .mfgDamping
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .ontSn
                                      }}
                                    </td>
                                    <td>
                                      <v-icon
                                        v-if="
                                          serviceOrderConnectionListArray[index]
                                            .otdrProtocol
                                        "
                                        color="success"
                                      >
                                        mdi-check-circle
                                      </v-icon>
                                      <v-icon v-else color="error">
                                        mdi-close-circle-outline
                                      </v-icon>
                                    </td>
                                    <td>
                                      <v-icon
                                        v-if="
                                          serviceOrderConnectionListArray[index]
                                            .proofOfInstallation
                                        "
                                        color="success"
                                      >
                                        mdi-check-circle
                                      </v-icon>
                                      <v-icon v-else color="error">
                                        mdi-close-circle-outline
                                      </v-icon>
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .deviceHostname
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .deviceSlot
                                      }}
                                    </td>
                                    <td>
                                      {{
                                        serviceOrderConnectionListArray[index]
                                          .devicePort
                                      }}
                                    </td>
                                  </template>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
              <!-- {{ item }} -->
            </div>
          </td>
          <!-- <h2>Adresse</h2>
          <pre>
            {{ item }}
          </pre> -->
          <!-- <tr
            v-for="(order, index) in item.serviceOrders"
            :key="order.contractId"
            :class="
              item.serviceOrders.length === 1
                ? 'spot-box-shadow'
                : index === 0
                  ? 'spot-box-shadow-top'
                  : index === item.serviceOrders.length - 1
                    ? 'spot-box-shadow-bottom'
                    : ''
            "
          >
            <td></td>
            <td v-if="order.contractId !== undefined">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web
                  </v-icon>
                </template>
                <span>Diensteauftrag</span>
              </v-tooltip>
            </td>
            <td>
              {{ order.contractId }}
            </td>
            <td>
              {{ order.exportDate }}
            </td>
            <td>
              {{ order.exportUser }}
            </td>
            <td>
              {{ order.address.zipCode }}
            </td>
            <td>
              {{ order.address.city }}
            </td>
            <td>
              {{ order.address.district }}
            </td>
            <td>
              {{ order.address.street }}
            </td>
            <td>
              {{ order.address.houseNumber }}
              {{ order.address.houseNumberSupplement }}
            </td>
            <td>
              {{ order.customerId }}
            </td>
            <td>
              {{ order.address.firstName }}
            </td>
            <td>
              {{ order.address.lastName }}
            </td>
            <td>
              {{ order.address.phone }}
            </td>
            <td>
              {{ order.address.cellPhoneNumber }}
            </td>
            <td>
              {{ order.address.email }}
            </td>
          </tr> -->
        </template>
      </v-data-table>
    </v-card>
    <!-- City/District tab view -->
    <template v-if="cityDistrictTabActive">
      <v-data-table
        v-model="selected"
        :headers="tableHeaders"
        :items="tableData"
        item-key="contractId"
        class="elevation-1"
        :single-expand="false"
        show-expand
        :expanded.sync="expandedRows"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="dataLoading"
        loading-text="Daten werden geladen..."
        no-data-text="Es sind keine Einträge vorhanden."
        no-results-text="Für den angegebenen Ort konnten keine Einträge gefunden werden."
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        }"
        :items-per-page.sync="rowsPerPageDefault"
        @item-expanded="
          if ($event.value === true) {
            loadServiceOrdersAtInstallationAddress($event.item);
          }
        "
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>
              Übersicht der Bauliste eines Ortsteils
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template #item="row">
          <tr
            :class="
              row.isExpanded
                ? 'v-data-table__expanded v-data-table__expanded__row'
                : ''
            "
            @click="row.expand(!row.isExpanded)"
          >
            <td></td>
            <td v-if="row.item.hasDuplicate">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="color: red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Zwei Installationsadressen im Vertrag</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Hausanschluss</span>
              </v-tooltip>
            </td>
            <td>{{ row.item.contractId }}</td>
            <td>{{ row.item.exportDate }}</td>
            <td>{{ row.item.exportUser }}</td>
            <td>{{ row.item.address.zipCode }}</td>
            <td>{{ row.item.address.city }}</td>
            <td>{{ row.item.address.district }}</td>
            <td>{{ row.item.address.street }}</td>
            <td>
              {{ row.item.address.houseNumber }}
              {{ row.item.address.houseNumberSupplement }}
            </td>
            <td>{{ row.item.customerId }}</td>
            <td>{{ row.item.address.firstName }}</td>
            <td>{{ row.item.address.lastName }}</td>
            <td>{{ row.item.address.phone }}</td>
            <td>{{ row.item.address.cellPhoneNumber }}</td>
            <td>{{ row.item.address.email }}</td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item }">
          <tr
            v-for="(order, index) in item.serviceOrders"
            :key="order.contractId"
            :class="
              item.serviceOrders.length === 1
                ? 'spot-box-shadow'
                : index === 0
                  ? 'spot-box-shadow-top'
                  : index === item.serviceOrders.length - 1
                    ? 'spot-box-shadow-bottom'
                    : ''
            "
          >
            <td></td>
            <td v-if="order.contractId !== undefined">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web
                  </v-icon>
                </template>
                <span>Diensteauftrag</span>
              </v-tooltip>
            </td>
            <td>
              {{ order.contractId }}
            </td>
            <td>
              {{ order.exportDate }}
            </td>
            <td>
              {{ order.exportUser }}
            </td>
            <td>
              {{ order.address.zipCode }}
            </td>
            <td>
              {{ order.address.city }}
            </td>
            <td>
              {{ order.address.district }}
            </td>
            <td>
              {{ order.address.street }}
            </td>
            <td>
              {{ order.address.houseNumber }}
              {{ order.address.houseNumberSupplement }}
            </td>
            <td>
              {{ order.customerId }}
            </td>
            <td>
              {{ order.address.firstName }}
            </td>
            <td>
              {{ order.address.lastName }}
            </td>
            <td>
              {{ order.address.phone }}
            </td>
            <td>
              {{ order.address.cellPhoneNumber }}
            </td>
            <td>
              {{ order.address.email }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-overlay v-if="loadingExport">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-overlay>
    </template>
    <!-- Project details -->
    <!-- <pre>
      {{ constructionListData }}
    </pre>
    <template v-if="projectTabActive">
      <v-data-table
        v-model="selected"
        :headers="projectTableHeaders"
        :items="projectTableData"
        item-key="contractId"
        class="elevation-1"
        :single-expand="false"
        show-expand
        :expanded.sync="expandedRows"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="dataLoading"
        loading-text="Daten werden geladen..."
        no-data-text="Es sind keine Einträge vorhanden."
        no-results-text="Für den angegebenen Ort konnten keine Einträge gefunden werden."
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          'items-per-page-text': 'Zeilen pro Seite:',
          'items-per-page-options': [10, 25, 50, 100, -1]
        }"
        :items-per-page.sync="rowsPerPageDefault"
        @item-expanded="
          if ($event.value === true) {
            loadServiceOrdersAtInstallationAddressForProject($event.item);
          }
        "
      >
        <template #top>
          <v-toolbar flat>
            <v-toolbar-title>
              Übersicht der Bauliste eines Ortsteils
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template #item="row">
          <tr
            :class="
              row.isExpanded
                ? 'v-data-table__expanded v-data-table__expanded__row'
                : ''
            "
            @click="row.expand(!row.isExpanded)"
          >
            <td></td>
            <td v-if="row.item.hasDuplicate">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="color: red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Zwei Installationsadressen im Vertrag</span>
              </v-tooltip>
            </td>
            <td v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-home-city
                  </v-icon>
                </template>
                <span>Hausanschluss</span>
              </v-tooltip>
            </td>
            <td>{{ row.item.contractId }}</td>
            <td>{{ row.item.exportDate }}</td>
            <td>{{ row.item.exportUser }}</td>
            <td>{{ row.item.address.zipCode }}</td>
            <td>{{ row.item.address.city }}</td>
            <td>{{ row.item.address.district }}</td>
            <td>{{ row.item.address.street }}</td>
            <td>
              {{ row.item.address.houseNumber }}
              {{ row.item.address.houseNumberSupplement }}
            </td>
            <td>{{ row.item.customerId }}</td>
            <td>{{ row.item.address.firstName }}</td>
            <td>{{ row.item.address.lastName }}</td>
            <td>{{ row.item.address.phone }}</td>
            <td>{{ row.item.address.cellPhoneNumber }}</td>
            <td>{{ row.item.address.email }}</td>
          </tr>
        </template>
        <template v-slot:expanded-item="{ item }">
          <tr
            v-for="(order, index) in item.serviceOrders"
            :key="order.contractId"
            :class="
              item.serviceOrders.length === 1
                ? 'spot-box-shadow'
                : index === 0
                  ? 'spot-box-shadow-top'
                  : index === item.serviceOrders.length - 1
                    ? 'spot-box-shadow-bottom'
                    : ''
            "
          >
            <td></td>
            <td v-if="order.contractId !== undefined">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-web
                  </v-icon>
                </template>
                <span>Diensteauftrag</span>
              </v-tooltip>
            </td>
            <td>
              {{ order.contractId }}
            </td>
            <td>
              {{ order.exportDate }}
            </td>
            <td>
              {{ order.exportUser }}
            </td>
            <td>
              {{ order.address.zipCode }}
            </td>
            <td>
              {{ order.address.city }}
            </td>
            <td>
              {{ order.address.district }}
            </td>
            <td>
              {{ order.address.street }}
            </td>
            <td>
              {{ order.address.houseNumber }}
              {{ order.address.houseNumberSupplement }}
            </td>
            <td>
              {{ order.customerId }}
            </td>
            <td>
              {{ order.address.firstName }}
            </td>
            <td>
              {{ order.address.lastName }}
            </td>
            <td>
              {{ order.address.phone }}
            </td>
            <td>
              {{ order.address.cellPhoneNumber }}
            </td>
            <td>
              {{ order.address.email }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-overlay v-if="loadingExport">
        <v-progress-circular
          :size="120"
          color="primary"
          indeterminate
        >
        </v-progress-circular>
      </v-overlay>
    </template> -->
    <v-dialog v-model="asyncReportStarted" max-width="720">
      <v-card>
        <v-card-title class="text-h5">
          Asynchroner Report gestartet
        </v-card-title>
        <v-card-text>
          <p>Der Asynchrone Report wurde gestartet.</p>
          <p>
            Das Ergebnis kann in der
            <router-link to="/">
              Aufgabenliste (GNC Export steht bereit)
            </router-link>
            abgerufen werden, sobald die Datei erzeugt wurde.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-alert dense type="info">
            Das erzeugen der Datei kann mehrere Minuten dauern.
          </v-alert>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="asyncReportStarted = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openHouseConnectionDialog" max-width="720">
      <v-card>
        <v-toolbar color="primary" dark>
          Hausanschluss
          <i style="font-size: 12px; margin-left: 15px">
            (<b>{{ selectedStreetNr }}</b>
            <template v-if="selectedContract">
              , Vertrag {{ selectedContract }})
            </template>
            <template v-else>
              - - - kein Hausanschlussauftrag vorhanden - - - nur
              Verbindungsdaten!)
            </template>
          </i>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="openHouseConnectionDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <HouseConnectionForm
            :g-ags="selectedProperty"
            :existing-house-connection="currentHouseConnectionListData"
            @close="openHouseConnectionDialog = false"
          />
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary">
            Speichern
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog v-model="openServiceConnectionDialog" max-width="1024">
      <v-card v-if="selectedContract">
        <v-toolbar color="primary" dark>
          Kundenanschluss (Versorgungseinheit)
          <i style="font-size: 12px; margin-left: 15px">
            ({{ selectedStreetNr }}, Vertrag {{ selectedContract }})</i>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="openServiceConnectionDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <SupplyUnitConnectionForm
            :home-id="selectedHomeId"
            :g-ags="selectedProperty"
            :existing-supply-unit="serviceOrderConnectionListArray"
            @close="openServiceConnectionDialog = false"
          />
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary">
            Speichern
          </v-btn>
        </v-card-actions> -->
      </v-card>
      <v-card v-else>
        Kein Vertrag an dieser Adresse vorhanden
      </v-card>
    </v-dialog>
    <v-dialog v-model="openCardDialog" max-width="1024">
      <v-card>
        <v-toolbar color="primary" dark>
          <span v-if="selectedItem">
            {{ selectedItem.address.street }}
            {{ selectedItem.address.houseNumber }}
            <template v-if="selectedItem.address.houseNumberSupplement">{{
              selectedItem.address.houseNumberSupplement
            }}</template>, {{ selectedItem.address.city }}
            <template
              v-if="
                selectedItem.address.district &&
                  selectedItem.address.city != selectedItem.address.district
              "
            >({{ selectedItem.address.district }})</template>
          </span>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="openCardDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="propertyResult && propertyResult.geo3857">
          <DetailMap
            :point="formatPointArray(propertyResult.geo3857)"
            :height="'600px'"
            :zoom="18"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PortingProcessDashboard from '@/dashboard/PortingProcessDashboard.vue';
import NotificationObject from '@/main/NotificationObject.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import DatePicker from '@/components/elements/DatePicker.vue';
import DetailMap from '@/components/constructionProjects/DetailMap.vue';
import { HTTP } from '@/main/httpClient.js';
import loader from '@/components/Loader.vue';
export default {
  name: 'OrderOverview',
  components: {
    PortingProcessDashboard,
    NotificationObject,
    DatePicker,
    HTTP,
    loader,
    DetailMap,
    AddressDistrictSelection: () =>
      import('@/components/statistic/AddressDistrictSelection.vue'),
    ProjectNameSelection: () =>
      import('@/components/statistic/ProjectNameSelection.vue'),
    HouseConnectionForm: () =>
      import('@/components/onlineConstructionList/houseConnectionForm.vue'),
    SupplyUnitConnectionForm: () =>
      import(
        '@/components/onlineConstructionList/supplyUnitConnectionForm.vue'
      ),
    UpdateProjectStatus: () =>
      import('@/components/onlineConstructionList/updateProjectStatus.vue')
  },
  data: () => ({
    loadingExport: false,
    rowsPerPageDefault: 50,
    expanded: [],
    selectedItem: null,
    dialog: false,
    dataLoading: false,
    tableData: [],
    projectTableData: [],
    selected: [],
    sortBy: 'dateProcessStart',
    sortDesc: true,
    addresses: undefined,
    singleExpand: false,
    expandedRows: [],
    toggles: [],
    city: null,
    district: '',
    districtItems: [],
    availableCities: [],
    isUpdating: false,
    search: '',
    loadingCities: false,
    loadingDistricts: false,
    districtObject: undefined,
    projectTabActive: true,
    cityDistrictTabActive: false,
    projectItem: undefined,
    projectState: null,
    projectStates: [],
    newProjectState: undefined,
    currentProjectState: {},
    filterInput: undefined,
    filterCategory: null,
    filterCategories: [
      {
        label: 'Kunden-Nr',
        value: 'customerId'
      },
      {
        label: 'Vertrags-Nr',
        value: 'contractId'
      },
      {
        label: 'Straße',
        value: 'street'
      },
      {
        label: 'Vorname',
        value: 'firstName'
      },
      {
        label: 'Nachname',
        value: 'lastName'
      },
      {
        label: 'Firma',
        value: 'company'
      }
    ],
    filteredAgsKeys: undefined,
    exportProjectAsync: false,
    asyncReportStarted: false,
    tableHeaders: [
      { text: 'Typ' },
      {
        text: 'Vertrags ID',
        align: 'start',
        sortable: true,
        value: 'contractId',
        class: 'title-attr'
      },
      { text: 'Export Datum', value: 'exportDate' },
      { text: 'Export User', value: 'exportUser' },
      { text: 'PLZ', value: 'address.zipCode' },
      { text: 'Ort', value: 'address.city' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      { text: 'Kundennummer', value: 'customerId' },
      { text: 'Vorname', value: 'address.firstName' },
      { text: 'Nachname', value: 'address.lastName' },
      { text: 'Festnetz', value: 'address.phone' },
      { text: 'Mobil', value: 'address.cellPhoneNumber' },
      { text: 'E-Mail', value: 'address.email' }
    ],
    projectTableHeaders: [
      { text: 'Typ' },
      {
        text: 'Vertrags ID',
        align: 'start',
        sortable: true,
        value: 'contractId',
        class: 'title-attr'
      },
      { text: 'Export Datum', value: 'exportDate' },
      { text: 'Export User', value: 'exportUser' },
      { text: 'PLZ', value: 'address.zipCode' },
      { text: 'Ort', value: 'address.city' },
      { text: 'Ortsteil', value: 'address.district' },
      { text: 'Straße', value: 'address.street' },
      { text: 'Hausnummer', value: 'address.houseNumber' },
      { text: 'Kundennummer', value: 'customerId' },
      { text: 'Vorname', value: 'address.firstName' },
      { text: 'Nachname', value: 'address.lastName' },
      { text: 'Festnetz', value: 'address.phone' },
      { text: 'Mobil', value: 'address.cellPhoneNumber' },
      { text: 'E-Mail', value: 'address.email' }
    ],
    onlineKblHeaders: [
      {
        text: 'RFS',
        value: 'ags27',
        width: '100px'
      },
      {
        text: 'Liegenschaftsstatus',
        value: 'markedForSecondConstruction',
        width: '100px'
      },
      { text: 'Straße', value: 'address.street', width: '250px' },
      { text: 'Haus-Nr.', value: 'address.houseNumber', width: '100px' },
      // { text: 'G-AGS-Key', value: 'ags27' },
      { text: 'PLZ', value: 'address.zipCode', width: '150px' },
      { text: 'Ort', value: 'address.city', width: '200px' },
      { text: 'Ortsteil', value: 'address.district', width: '200px' },
      {
        text: 'Hausanschluss-Verträge',
        value: 'houseConnectionsNo',
        width: '50px'
      },
      { text: 'Service-Verträge', value: 'serviceConnectionsNo' }
    ],
    onlineKblHeadersNu: [
      {
        text: 'Liegenschaftsstatus',
        value: 'address.phone',
        width: '100px'
      },
      { text: 'Straße', value: 'address.street', width: '250px' },
      { text: 'Haus-Nr.', value: 'address.houseNumber', width: '100px' },
      // { text: 'G-AGS-Key', value: 'ags27' },
      { text: 'PLZ', value: 'address.zipCode', width: '150px' },
      { text: 'Ort', value: 'address.city', width: '200px' },
      { text: 'Ortsteil', value: 'address.district', width: '200px' },
      {
        text: 'Hausanschluss-Verträge',
        value: 'houseConnectionsNo',
        width: '50px'
      },
      { text: 'Service-Verträge', value: 'serviceConnectionsNo' },
      { text: 'MFG', value: undefined },
      { text: 'NVT', value: undefined },
      { text: 'NVT-Spleißkassete', value: undefined },
      { text: 'Rohrverband', value: undefined },
      { text: 'Einzelrohr', value: undefined },
      { text: 'Faseranzahl', value: undefined },
      { text: 'OTDR-Protokoll', value: undefined },
      { text: 'Begehungsprotokoll', value: undefined },
      { text: 'Montagenachweis', value: undefined }
    ],
    subTableHeaders: [
      { text: 'Name', value: 'firstname' },
      { text: 'Nachname', value: 'lastname' }
    ],
    constructionListData: undefined,
    currentHouseConnections: undefined,
    currentServiceConnections: undefined,
    openHouseConnectionDialog: false,
    openServiceConnectionDialog: false,
    openCardDialog: false,
    selectedProperty: undefined,
    selectedContract: undefined,
    selectedHomeId: undefined,
    selectedStreetNr: undefined,
    propertyResult: undefined,
    currentHouseConnectionListData: undefined,
    serviceOrderConnectionListArray: [],
    availableProjectsNu: [
      {
        projektId: 807,
        name: 'Halle, Hunzen',
        city: 'Halle',
        district: 'Hunzen',
        county: 'Holzminden',
        state: 'Niedersachsen'
      }
    ],
    onlyMarkedForSecondConstruction: false,
    constructionManagementTool: undefined,
    constructionManagementToolOptions: [
      'Online_Kundenbauliste',
      'Excel-Kundenbauliste',
      'TKI'
    ]
  }),
  computed: {
    showConstructionStartDate() {
      return this.newProjectState.name ===
        this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value
        ? this.constructionStartDate
        : undefined;
    },
    caption() {
      return `Adressen deren Baustatus zu ${this.propertyState.join(
        ', '
      )} geändert werden soll`;
    },
    username() {
      return this.$auth.user().userName;
    },
    userGroups() {
      return this.$auth.user().userRoles;
    },
    filteredAddresses() {
      if (
        this.constructionListData &&
        this.constructionListData.length &&
        this.onlyMarkedForSecondConstruction
      ) {
        const filtered = this.constructionListData.filter(
          (item) => item.markedForSecondConstruction
        );
        return filtered;
      } else {
        return this.constructionListData;
      }
    }
  },
  watch: {
    projectItem: function () {
      this.changeRequestFlag = false;
      this.clear();
      this.getCurrentProjectState();
      this.constructionListData = undefined;
    },
    currentProjectState: {
      handler: function (newValue, oldValue) {
        if (newValue.name) {
          this.findPossibleProjectStates();
        }
      },
      deep: true
    },
    filterInput(newValue) {
      if (newValue && newValue.length >= 3) {
        this.kblFilter(newValue);
      } else {
        this.kblFilter(newValue);
      }
    }
  },
  created() {
    this.projectStateConstants = {
      NOT_SET: {
        value: 'Nicht gesetzt'
      },
      PROJECT_PLANNED: {
        value: 'Projekt geplant'
      },
      PROJECT_READY: {
        value: 'Projekt abgeschlossen'
      },
      PROJECT_CANCELED: {
        value: 'Projekt abgebrochen'
      },
      PROJECT_CONSTRUCTION_STARTED: {
        value: 'Bau gestartet'
      }
    };
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    },
    exportGncConstructionList: function () {
      this.loadingExport = true;
      let zip;
      let cityString;
      let districtString;
      if (
        this.districtObject &&
        this.districtObject.zip &&
        this.districtObject.city &&
        this.districtObject.district
      ) {
        zip = this.districtObject.zip;
        cityString = this.districtObject.city;
        districtString = this.districtObject.district;
      } else {
        console.log('Wrong condition true');
        zip = this.city.zip;
        cityString = this.city.city;
        districtString = this.district ? this.district : '';
      }

      HTTP.get(
        `/gncReport/exportGncConstructionList?zip=${zip}&city=${cityString}&district=${districtString}`,
        { responseType: 'blob' }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportProjectData() {
      this.loadingExport = true;

      if (this.exportProjectAsync) {
        return this.exportProjectAsyncStart();
      }

      HTTP.get(
        `/gncReport/exportGncConstructionList?projectId=${this.projectItem.projektId}`,
        {
          responseType: 'blob'
        }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportProjectAsyncStart: function () {
      HTTP.post(
        `/gncReport/requestAsyncGncReport?projectId=${this.projectItem.projektId}`
      )
        .then((resp) => {
          this.asyncReportStarted = true;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    loadServiceOrdersAtInstallationAddress: function (item) {
      HTTP.get('/gncReport/getServiceOrdersList', {
        params: {
          zip: item.address.zipCode,
          street: item.address.street,
          houseNo: item.address.houseNumber,
          houseNoSup: item.address.houseNumberSupplement
        }
      })
        .then((resp) => {
          if (resp.data !== null) {
            // item.serviceOrders = resp.data;
            this.serviceOrderConnectionListArray = [];
            this.currentServiceConnections = resp.data;
            resp.data.forEach((item) => {
              if (item && item.homeId) {
                this.loadServiceOrderConnectionList(item.homeId);
              }
            });
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {});
    },
    loadHouseConnectionOrdersAtInstallationAddress: function (item) {
      HTTP.get('/gncReport/getHouseConnectionOrdersList', {
        params: {
          zip: item.address.zipCode,
          street: item.address.street,
          houseNo: item.address.houseNumber,
          houseNoSup: item.address.houseNumberSupplement
        }
      })
        .then((resp) => {
          if (resp.data !== null) {
            // item.houseConnectionOrders = resp.data;
            this.currentHouseConnections = resp.data;
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {});
    },
    loadHouseConnectionList: function (item) {
      this.currentHouseConnectionListData = undefined;
      if (item && item.ags27) {
        HTTP.get('/past/geo/house-connection', {
          params: {
            gAgs: item.ags27
          }
        })
          .then((resp) => {
            if (resp.data !== null && resp.data[0]) {
              // item.houseConnectionOrders = resp.data;
              this.currentHouseConnectionListData = resp.data[0];
            }
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Die Daten konnten nicht geladen werden'
              )
            )
          )
          .finally(() => {});
      }
    },
    loadServiceOrderConnectionList: function (item) {
      if (item) {
        HTTP.get('/past/geo/supply-unit-connection', {
          params: {
            homeId: item
          }
        })
          .then((resp) => {
            if (resp.data !== null && resp.data[0]) {
              // item.houseConnectionOrders = resp.data;
              this.serviceOrderConnectionListArray.push(resp.data[0]);
            }
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Die Daten konnten nicht geladen werden'
              )
            )
          )
          .finally(() => {});
      }
    },
    loadServiceOrdersAtInstallationAddressForProject: function (item) {
      HTTP.get('/gncReport/serviceOrderContracts/byProject', {
        params: {
          zip: item.address.zipCode,
          street: item.address.street,
          houseNo: item.address.houseNumber,
          houseNoSup: item.address.houseNumberSupplement,
          projectId: this.projectItem.projektId
        }
      })
        .then((resp) => {
          if (resp.data !== null) {
            // item.serviceOrders = resp.data;
            this.currentHouseConnections = resp.data;
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {});
    },
    getHouseconnectionContractsByCityCodeAndDistrict: function () {
      if (
        (this.city && this.city.city && this.city.zip) ||
        (this.districtObject &&
          this.districtObject.zip &&
          this.districtObject.city &&
          this.districtObject.district)
      ) {
        this.loading = true;
        let zip;
        let citystring;
        let districtString;
        if (
          this.districtObject &&
          this.districtObject.zip &&
          this.districtObject.city &&
          this.districtObject.district
        ) {
          zip = this.districtObject.zip;
          citystring = this.districtObject.city;
          districtString = this.districtObject.district;
        } else if (this.city && this.city.zip && this.city.city) {
          zip = this.city.zip;
          citystring = this.city.city;
          districtString = this.district ? this.district : '';
        }
        this.dataLoading = true;
        HTTP.get(
          `/gncReport/addressesWithHouseconnectionOrder?zip=${zip}&city=${citystring}&district=${districtString}`
        )
          .then((resp) => {
            this.tableData = resp.data.map((item) => {
              return {
                serviceOrders: [],
                contractTypeIcon: 'mdi-web',
                ...item
              };
            });
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Die Daten konnten nicht geladen werden'
              )
            )
          )
          .finally(() => {
            this.dataLoading = false;
          });
      }
    },
    updateCity(city) {
      this.city = city;
    },
    updateDistrict(district) {
      this.district = district;
      if (!district) {
        this.tableData = [];
      }
    },
    updateDistrictObject(obj) {
      this.districtObject = obj;
      if (!obj) {
        this.tableData = [];
      }
    },
    updateProjektItem(projectItem) {
      if (this.projectTabActive) {
        this.projectItem = projectItem;
        if (!projectItem) {
          this.projectTableData = [];
        }
      }
    },
    getProjectData() {
      this.dataLoading = true;
      let constructionListIncrement;
      HTTP.get(
        `/gncReport/gncConstructionListOnlineView?projectId=${this.projectItem.projektId}`
      )
        .then((response) => {
          constructionListIncrement = response.data;
          console.log(response.data);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht geladen werden'
            )
          )
        )
        .finally(() => {
          HTTP.get(
            `/past/geo/property-status-list?projektId=${this.projectItem.projektId}`
          )
            .then((response) => {
              const propertyStatusArray = response.data;
              // this.constructionListData = [
              //   ...this.constructionListData,
              //   ...propertyStatusArray
              // ];

              // const mergedArray = constructionListIncrement.concat(
              //   propertyStatusArray.filter(
              //     (item2) =>
              //       !constructionListIncrement.some(
              //         (item1) => item1.ags27 === item2.ags27
              //       )
              //   )
              // );

              // const mergedArray = constructionListIncrement.map((item, i) =>
              //   Object.assign({}, item, propertyStatusArray[i])
              // );

              const mergedArray = constructionListIncrement.map((obj) =>
                Object.assign(
                  obj,
                  propertyStatusArray.find((item) => obj.ags27 === item.ags27)
                )
              );

              //          const mergeById = (a1, a2) =>
              // a1.map(itm => ({
              //     ...a2.find((item) => (item.id === itm.id) && item),
              //     ...itm
              // }));

              this.constructionListData = mergedArray;

              console.log(mergedArray);
              // console.log(response.data);
            })
            .catch((err) =>
              this.showMessage(
                'error',
                ErrorMessageBuilder.buildErrorMessage(
                  err.response.data,
                  'Die Daten konnten nicht geladen werden'
                )
              )
            )
            .finally(() => {
              this.dataLoading = false;
            });
        });
    },
    rowClass(item) {
      if (item.houseConnectionsNo) {
        return 'table-bg-success-light';
      } else if (item.serviceConnectionsNo) {
        return 'table-bg-warning-light';
      }
    },
    expandRow(item) {
      // would be
      // this.expanded = [item]
      // but if you click an expanded row, you want it to collapse
      this.expanded = item === this.expanded[0] ? [] : [item];
      this.currentHouseConnections = undefined;
      this.currentServiceConnections = undefined;
      this.loadHouseConnectionOrdersAtInstallationAddress(item);
      this.loadServiceOrdersAtInstallationAddress(item);
      this.loadHouseConnectionList(item);
    },
    findPossibleProjectStates() {
      this.projectStates = [];
      // Admin is allowed to change to any state at any time
      if (this.userGroups.includes('camunda-admin')) {
        this.projectStates.push({
          name: this.projectStateConstants.NOT_SET.value,
          disable: true
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_PLANNED.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_READY.value,
          disable: false
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CANCELED.value,
          disable: false
        });
      } else {
        this.projectStates.push({
          name: this.projectStateConstants.NOT_SET.value,
          disable: true
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_PLANNED.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_PLANNED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CONSTRUCTION_STARTED.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_READY.value,
          disable:
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_READY.value ||
            this.currentProjectState.name ===
              this.projectStateConstants.PROJECT_CANCELED.value
        });
        this.projectStates.push({
          name: this.projectStateConstants.PROJECT_CANCELED.value,
          disable: false
        });
      }
    },
    getConstructionStartDate(currentProjectStartDate) {
      var dateString;
      if (
        currentProjectStartDate !== '' &&
        currentProjectStartDate != null &&
        currentProjectStartDate !== undefined
      ) {
        const dateInMilliSec = Date.parse(currentProjectStartDate);
        const date = new Date(dateInMilliSec);
        dateString =
          date.getFullYear() +
          '-' +
          String(date.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(date.getDate()).padStart(2, '0');
      }
      return dateString;
    },
    getCurrentProjectState() {
      HTTP.get(
        `past/projects?where[projektId][equals]=${this.projectItem.projektId}`
      )
        .then((result) => {
          this.currentProjectStartDate = result.data[0].startDate;
          this.constructionStartDate = this.getConstructionStartDate(
            this.currentProjectStartDate
          );
          this.currentProjectState = {
            name:
              result.data[0].projectStatus.description !== null ||
              result.data[0].projectStatus.description !== ''
                ? result.data[0].projectStatus.description
                : this.projectStateConstants.NOT_SET.value,
            disable: true
          };
          this.newProjectState = this.currentProjectState;
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              `Der Projektstatus von Projekt ${this.projectItem.projektId} konnte nicht ermittelt werden.`
            )
          )
        );
    },
    clear() {
      this.newProjectState = undefined;
      this.currentProjectState = {};
    },
    formatDate(value) {
      // const date = new Date(value);
      // return date.getDate() + '.' + date.getFullMonth() + '.' + date.getFullYear();
      const date = new Date(value);
      const result = date.toLocaleDateString('de-DE', {
        // you can use undefined as first argument
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return result;
    },
    kblFilter(value) {
      const qs = '?' + this.filterCategory + '=' + value;

      if (this.filterCategory && value && value.length >= 3) {
        HTTP.get(`/gncReport/getAgs27List/${this.projectItem.projektId}` + qs)
          .then((result) => {
            this.filteredAgsKeys = result.data;
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Suche fehlgeschlagen'
              )
            )
          );
      }
    },
    getPropertyForMap(item) {
      this.loading = true;
      const zipCode =
        item.address && item.address.zipCode ? item.address.zipCode : '';
      const city = item.address && item.address.city ? item.address.city : '';
      const street =
        item.address && item.address.street ? item.address.street : '';
      const houseNumber =
        item.address && item.address.houseNumber
          ? item.address.houseNumber
          : '';
      const houseNumberSupplement =
        item.address && item.address.houseNumberSupplement
          ? item.address.houseNumberSupplement
          : '';

      const queryString =
        '?zip=' +
        zipCode +
        '&city=' +
        city +
        '&street=' +
        street +
        '&houseno=' +
        houseNumber +
        '&errorToleranceMeters=0' +
        '&housenoSuppl=' +
        houseNumberSupplement +
        '&similarity=100';

      HTTP.get('/past/geo/property-status' + queryString)
        .then((response) => {
          this.propertyResult = response.data ? response.data : undefined;
          this.openCardDialog = true;
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit(
            'addNotification',
            new NotificationObject(
              'warning',
              'Fehlerhafte Adresse oder Adresse bereits vorhanden'
            )
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatPointArray(point) {
      const convertedPoint = [];
      if (point && point.includes('POINT(')) {
        const pointArray = point
          .replace('POINT(', '')
          .replace(')', '')
          .split(' ');
        convertedPoint.push(parseFloat(pointArray[0]));
        convertedPoint.push(parseFloat(pointArray[1]));
      }
      return convertedPoint;
    },
    getProjectItemText(item) {
      let projectLabel;
      if (item) {
        projectLabel = `${item.projektId},  ${item.name}, (${item.district}, ${item.city}, Landkreis ${item.county})`;
      }
      return projectLabel;
    }
  }
};
</script>
<style lang="scss" scoped>
.red {
  box-shadow: 0 0 0 100vmax rgba(0, 0, 0, 0.6);
}
.spot-box-shadow-bottom {
  box-shadow: inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75);
}

.spot-box-shadow-top {
  box-shadow: inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75);
}
.spot-box-shadow {
  box-shadow: inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75);
}
.v-data-table ::v-deep .table-bg-success-light {
  background: rgba(0, 250, 0, 0.05);
}
.v-data-table ::v-deep .table-bg-warning-light {
  background: rgba(250, 200, 0, 0.05);
}
.v-data-table ::v-deep .table-bg-error-light {
  background: rgba(250, 0, 0, 0.05);
}
.v-data-table .v-data-table__expanded .v-data-table table tbody tr {
  border-bottom: 3px solid #333;
  td {
    font-weight: bold;
    font-size: 14px;
    // background: rgba(0, 133, 199, 0.5);
  }
}
.v-data-table
  .v-data-table__expanded
  .v-data-table
  table
  tbody
  tr
  ::v-deep
  table {
  box-shadow: inset 1px 2px 5px rgba(0, 0, 0, 0.2);
}
.v-data-table {
  .status-green {
    width: 30px;
    height: 30px;
    background-color: #80ed99;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #80ed99;
  }
  .status-red {
    width: 30px;
    height: 30px;
    background-color: #ff6b6b;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #ff6b6b;
  }
  .status-yellow {
    width: 30px;
    height: 30px;
    background-color: #ffe100;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #ffe100;
  }
  .status-grey {
    width: 30px;
    height: 30px;
    background-color: #aaa;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #aaa;
  }
  .status-white {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px 1px #aaa;
  }
}
.filter-box {
  padding-top: 5px;
  display: flex;
}
</style>
